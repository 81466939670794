// Form styling starts here
.user-form {
  background: #fff;
  border-radius: 8px;
  width: 100%;
  width: 400px;

  div {
      &.form-group {
      position: relative;
      }

  .error {
      color: #d9534f;
      font-size: 0.8rem;
      margin-top: 0.5rem;
      position: absolute;
      bottom: -1rem;
      left: 0;
      width: 100%;
      display: inline-block;
      text-align: right;
      bottom: -18px;
  }

  label {
      & .required-input-field {
          color: rgb(193, 24, 24);
          vertical-align: middle;
      }
  }

  input {
      &.error-input {
      border-color: #d9534f;
      }
      }
  }
}

.validation-error {
    color: #d9534f;
    font-size: 0.8rem;
    text-align: right;
}

.scrollable-view {
    overflow: auto;
    height: 35vh;
    padding: 0.5rem;
    padding-bottom: 1rem;
  }

input[name="total_cases"] {
    text-align: right;
}
