.inbound-list {
  .head-wrapper {
    width: fit-content;
  }

  .body-wrapper {
    max-height: 300px;
    overflow: auto;
    width: fit-content;

    & div {
      overflow: initial;
      width: 100%;
    }

    & table {
      width: 100%;
    }
  }
}
