.nav-container {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;

  .nav-left {
    display: flex;

    .brand {
      width: 160px;
    }
  }

  img.logo {
    height: 40px;
    margin-left: 12px;
    display: block;
  }

  .tenant {
    display: flex;
    align-items: center;
    column-gap: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #413d5b;
  }

  .avatar {
    width: 36px;
    height: 36px;
    background-color: #0f172a;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
