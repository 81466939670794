@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Ubuntu',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6eff8;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #e6eaef;
}

// often reused classes
.justify-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

h1,
h2,
h4 {
  margin: 0;
}

.row {
  display: flex;
  align-items: center;
  column-gap: 8px;

  &.head {
    justify-content: space-between;
  }
}

.custom-table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .action {
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .head {
    margin-bottom: 16px;
  } 
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.table-print {
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 12px;
  border: 1px solid #ccc;
  width: 100%;

  &.has-footer {
    tbody tr:last-child td {
      font-weight: bold;
    }
  }

  thead {
    display: table-header-group;

    tr {
      page-break-inside: avoid;
    }
  }

  tbody tr {
    page-break-inside: avoid;
  }

  th,
  td {
    padding: 8px;
    border-bottom: 1px solid #ccc;
  }

  &.receipts {
    tbody tr:nth-child(odd) td {
      border-bottom: 0;
    }

    tbody tr:nth-child(even) td {
      padding: 0;
    }

    tbody tr:nth-child(even) .table-inner-container .table-inner td {
      padding: 2px 4px;
    }
  }

  th {
    background-color: #eee;
    font-weight: bold;
    text-align: left;
  }

  td.currency,
  th.currency {
    text-align: right;
  }

  .voided {
    text-decoration: line-through;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .table-inner-container {
    display: flex;
    justify-content: flex-end;

    .table-inner {
      border: 0;
      width: 100%;

      td {
        padding: 2px 4px;
        border-bottom: 0;
      }
    }
  }
}

.only-print {
  display: none;
}

.print-container {
  overflow: auto;
  height: auto;
  max-height: auto;
  background-color: white;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

@media print {
  @page {
    margin: 10mm 5mm 5mm 10mm;
  }

  .only-print {
    display: block;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  .page-break {
    page-break-before: always;
  }
}
