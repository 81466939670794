// To override pre applied styles for react date picker
.rdp-root {
  --rdp-accent-color: #1D4ED8;
  --rdp-accent-background-color: #eceffc;
  --rdp-font-family: 'Rubik';
  --rdp-day-height: 2.4rem;
  --rdp-day-width: 2.4rem;
  --rdp-day_button-border-radius: 5px;
  --rdp-months-gap: 1rem;
  --rdp-month_caption-font: medium var(--rdp-font-family);
  --rdp-selected-font: medium var(--rdp-font-family);
  --rdp-chevron-fill-color: #1f1f1f;
}

.rdp-chevron {
  display: inline-block;
  fill: var(--rdp-chevron-fill-color);
}
