.container {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 0;
  row-gap: 20px;

  .content-wrapper {
    display: flex;
    flex: 1 1 0;
    overflow: hidden;
    column-gap: 16px;

    .content {
      border-radius: 40px;
      background-color: #fff;
      flex: 1 1 0;
      padding: 36px;
      overflow-x: hidden;
    }
  }

  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 700, 'GRAD' 0, 'opsz' 48;
  }
}

.btn-container {

  button {
    width: 130px;
  }

  & .cancel-btn {
    border: 2px solid #b6b4b4;
  }
}

.flex-justify-end {
  justify-content: end;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.spin {
  animation: spin-animation 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@media (max-width: 1280px) {
  .btn-container {

    button {
      width: 100px;
    }
  }
}
