.nav-sidebar {
  display: flex;
  flex-direction: column;
  width: 176px;
  row-gap: 16px;

  .nav-dropdown-header{
    cursor: pointer;
  }

  .nav-link {
    color: #3460dc;
    font-size: 14px;
    text-decoration: none;
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 8px 16px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    &.active {
      color: #0f172a;
      font-weight: 500;
      background-color: #d3e3f3;
    }

    &:hover {
      background-color: #d3e3f3;
    }
  }

  > :first-child {
    margin-top: 20px;
  }

  .child-menu > .child-nav {
    margin-top: 5px;
    margin-left: 15px;
  }

}
