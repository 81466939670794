.status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;

    &.delivered, &.closed, &.complete {
        color: #047857;
        background-color: #DEFFF0;
    }

    &.shipped, &.expected {
        color: #3460DC;
        background-color: #E7EDFF;
    }

    &.printed, &.open {
        color: #FF6400;
        background-color: #faf3ee;
    }

    &.rejected, &.cancelled, &.damaged {
        color: #ef4111;
        background-color: #ffe0d7;
    }

    &.received {
        color: #676767;
        background-color: #EBEBEB;
    }
}

.edit-button {
    font-weight: normal;
  }

#package-icon svg {
    text-align: center;
    vertical-align: middle;
}

.linkContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 12px;
    justify-content: space-between;
    width: 15%;
}
