.review-content-wrapper {
  font-size: 16px;
}

.left-side-container {
  width: 34.5em;
  max-height: 60vh;
  overflow: auto;
  box-sizing: border-box;
}

.input-field {
  width: 12em;
  height: 2.8em;
}

.right-side-container {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
}

.review-container,
.misc-container {
  display: grid;
  grid-template-columns: 9.375em 1fr;
  color: #475569;
}

.review-container {
  width: 18.75em;
  gap: 1.25em 1.875em;

  img {
    max-width: 7.8125em;
  }

  .delivery {
    display: flex;
    flex-direction: column;
    font-size: 0.875em;
    gap: 1em;

    &-address {
      font-size: 1em;
    }

    &-address, &-name {
      display: inline-block;
      font-weight: bold;
    }

    &-invoice {
      font-weight: 500;
    }

    &-total_cases {
      font-size: 4em;
      font-weight: bold;
      justify-self: center;
      display: inline-block;
      align-self: center;
      color: #2b384a;
    }
  }
}

.margin-top-24px {
  margin-top: 1.875em;
}

.misc {
  &-container {
    font-size: 0.875em;
    gap: 1.5em;

    & .key {
      font-weight: 500;
    }
  }
}

.details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.875em;
}

.proof-of-delivery-container {
  margin: 2em;
}

.delivery-proof-image {
  width: 20vw;
  height: 50vh;
}

.cases-list-container {
  margin-top: 1.5em;
}

.height-45vh {
  height: 45vh;
}

.view-details-container {
  height: 56vh;
}

.line-status-container {
  display: flex;
  flex-direction: column;
}

.view-page-holder {
  max-height: 60vh;
}

@media (max-height: 700px) {
  .height-45vh {
    height: 34vh;
  }

  .view-details-container {
    height: 50vh;
  }

  .delivery-proof-image { 
    width: 15vw;
    height: 40vh;
  }
}

@media (max-width: 1280px) {
  .review-content-wrapper {
    font-size: 12px;
  }

  .left-side-container {
    max-height: 54vh;
  }

  .view-page-holder {
    max-height: 57vh;
  }

  .status, .status-text {
    font-size: 10px;
  }

  .input-field {
    width: 12em;
    height: 3.25em;
  }
}

.status-count {
  display: inline-block;
  min-width: 1.25em;
  height: 1.125em;
  border-radius: 0.25em;
  padding: 0 0.375em;
  text-align: center;
  line-height: 1.125em;
}

.status-printed {
  background-color: #ff6400;
  color: #FFFFFF;
}

.status-shipped {
  background-color: #3460dc;
  color: #FFFFFF;
}

.status-delivered {
  background-color: #047857;
  color: #FFFFFF;
}
