.settings-container > * {
  color: #070417;
}

.height-40px {
  height: 40px;
}

.warehouse-tab {
  flex: 0 1 60vw;
}

@media (max-width: 1200px) {
  .warehouse-tab {
    flex: 0 1 50vw;
  }
}

.location-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .MuiTableHead-root {
    .MuiTableCell-head {
      background-color: #EEEFF2;
    }
  }
}
